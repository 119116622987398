@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overflow-x: hidden;
}
.usercircle{color: #FF8159;}
body {
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
.dicoverheading {
  text-align: center;
  font-size: 50px;
  letter-spacing: 0px;
  opacity: 1;
}
.youtback {
  background-color: #f5f5f5;
  width: 100%;
  margin: 0px 0px;
  margin-top: 60px;
}
.welusr{
  margin-right: 20px;
}
.discovepagaasx {
  text-align: center;

  letter-spacing: 0px;
  color: #0c1524;
}
.dorpdownboot .btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  box-shadow: none;
  padding:auto;
}
.dorpdownboot .dropdown-toggle::after {
  display: none;
}
.referralcode{
  margin-right: 138px;
  margin-top: 3px;
}
.dorpdownboot .btn:hover, 
.dorpdownboot .btn:focus, 
.dorpdownboot .btn:active {
  background: none;
  border: none;
  box-shadow: none;
}
.discoverpage {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ff8159;
  border-radius: 40px;
  opacity: 1;
  padding: 34px 25px;
  display: flex;
  margin-top: 5px;
  text-align: left;
  font-size: 24px;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
  line-height: 34px;
  min-height: 179px;
}

/* General styles */
.youtubwtexttt {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.youtubwtexttt .text-content {
  font: normal normal 600 50px/63px Plus Jakarta Sans;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
}

.responsive-img {
  width: 404px;
  height: auto;
}

.formexel span {
  width: 198px;
}

.form-control{ border: 1px solid #999 !important;}

/*Mobile responsiveness */
@media (max-width: 576px) {
  .youtubwtexttt .text-content {
    font-size: 1rem;
  }
 
  .responsive-img {
    width: 50%;
    height: auto;
   margin-left: 20px;
  }


}

.aboutsec .ITRbox4{ background: none;}
.aboutsec .maincardmaximize{ border: 1px solid #FF8159;  }
.aboutsec .ITRbox4 { padding: 0px;}

.youtubwtexttt {
  display: flex;
}

.termsandcon h3{ margin-top: 30px;}
.termsandcon h1 { font-size: 50px;}
.termsandcon li{ list-style: disc;}


.txt {
  text-align: center;

  letter-spacing: 0px;
  color: #ff8159;
}
.cards {
  height: min-content;
}

.youtubwtext {
  text-align: center;
  font-weight: 600;
  font-size: 50px;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
}

.basic-nav-dropdownn {
  margin-left: 250px;
  margin-right: 44px;
}

#basic-nav-dropdown {
  margin-right: 44px;
}

.backgound {
  background: var(--Blue-darker, #1d3d64);
}
.leftsecimg {
  width: 100%;
  border-radius: 20px;
}
.secondsection {
  width: 91%;
  margin: 0px auto;
}
.tick-small {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.ytubesec .d-flex{ display: contents !important; }
.tick-icon {
  margin-right: 10px; /* Adjust the margin as needed */
}
.cardmainn {
  border-radius: 40px !important;
}
.youtubemain{ display: flex; justify-content: center;}
.btngetstarted {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #666666;
  border-radius: 10px;
  opacity: 1;
  padding: 6px 28px;
  text-align: center;

  text-transform: uppercase;
  font-size: 30px;

  font-weight: 600;

  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
}
.cardboysstyle {
  border-radius: 40px;
  width: 450px;
}
.but {
  border-radius: 8px;
  background: var(--Dark, #0c1826);
}

.infooter {
  width: 1920px;
  padding: 0px 0px;
  justify-content: center;
  align-items: center;
  gap: 92px;
}

.itrtitle {
  text-align: left;
  font-size: 50px;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
  font-weight: 600;
  margin-top: 200px;
}
.googleratings {
  margin-top: 160px;
}
.inputgetstarted {
  display: flex;
}

.inputgetstarted button {
  padding: 10px 30px;
  margin-left: 20px;
  height: 50px;
  margin-top: 15px;
  display: flex;
}

.inputgetstarted input {
  padding: 10px 20px;
  border: 1px solid rgba(29, 61, 100, 0.12);
  border-radius: 8px;
}

.secondsection h2 {
  font-size: 40px;
  font-weight: 700;
}

.modal-dialog {
  height: 400px;
  /* Adjust the value as needed */
}

.secondsection li {
  font-size: large;
}

.secondsection ul {
  margin-top: 50px;
}
.loginbtn{font-size: 16px;}
.btnfile {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ff8159;
  border-radius: 10px;
  opacity: 1;
  font-size: 30px;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
  font-weight: bold;
  padding: 16px 50px;}

.main1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 35px 35px;
  border-radius: 65px;
}
.maindiv {
  background-color: #fff;
}
.secondsection ul li {
  font-size: 18px;
  color: #fff;
  list-style: none;
  padding: 0 0 30px 20px;
  display: flex;
}
cardfile .secondsection ul li svg {
  margin-right: 10px;
}
.cardfile {
  margin-bottom: 20px;
}
.itrbtn {
  margin-left: 50px;
  font-size: 18px;
  background-color: #fff;
  padding: 10px 20px;
  display: flex;
}

.videobacke {
  background-color: #eaf4ff;
}
.sectionthird h2 {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;

  padding-bottom: 50px;
}
.sectionthirdwqw {

  align-items: center;
  justify-content: center;
  width: 91%;
  margin: 0px auto;
  padding:0px 165px;
}
.secp {
  text-align: center;
  font-size: 50px;

  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;

  font-weight: 600;
}
.seccp {
  text-align: center;
  margin-bottom: 40px;
  font-size: 24px;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
}
.bgimage {
  background-image: url("./Images//ru.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh; /* Adjust the height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.radius {
  border-radius: 40px;
}

.cardtwo {
  border-radius: 40px !important;
  box-shadow: 0px 0px 25px #ccdbeb;
}
.itrboxtwo {
  background: #eef2f6 0% 0% no-repeat padding-box;
  border-radius: 65px;
  opacity: 1;
  /* width: 91%; */
  margin: 0px auto;
  padding: 100px 0 80px 0;
}
.tititle {
  text-align: center;
  font-size: 30px !important;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
}
.titletext {
  text-align: center;
  font-size: 24px;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
}
.ITRbox3 h3 {
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 18px;
  font-weight: 600;


}


.ITRbox3{
  width: 91%;
  margin:0px auto;
  padding-top: 100px;
}

.bgimagemain h6 {
  font-weight: 400;
}
.maincardmax {
  margin-right: 10px;
}
.text-dark {
  font-size: 22px;
}

@media (max-width: 767px) {
  .constachead {
    font-size: 30px;
  }

  .contactsub {
    font-size: 18px !important;
  }

  .maincontactus label {
    font-size: 16px;
  }
}

.howitworks {
  background-color: #f8f9fa;
  padding: 40px 0;
}

.custom-card {
  background-color: #ffffff;
  border: 1px solid #ff8159;
  border-radius: 20px;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: auto;
  margin: 0;
}

.custom-card .simplifylogo {
  width: 70px;
  height: 70px;
  margin: 0 auto 20px;
}

.custom-card .simplifylogo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.custom-card h3 {
  margin-top: 20px;
  font-size: 1.5rem;
  color: #0c1524;
}

.custom-card p {
  margin-top: 20px;
  font-size: 1rem;
  color: #0c1524;
}

@media (max-width: 768px) {
  .custom-card {
    margin-bottom: 20px;
    padding: 15px; /* Adjust padding for small screens */
  }
  .custom-card h3 {
    font-size: 1.25rem; /* Adjust font size for small screens */
  }
  .custom-card p {
    font-size: 0.875rem; /* Adjust font size for small screens */
  }
}

@media (max-width: 768px) {
  .cardstyle {
    margin-top: 10px;
  }
}
@media (max-width: 768px) {
  .cardtwo {
    margin-top: 10px;
  }
}
.contactsub {
  font-size: 22px !important;
  text-transform: uppercase !important;
}
.maincontactus label {
  font-weight: 600;
  font-size: 18px;
}
.constachead {
  text-align: center;
  font-size: 50px;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
}
.imageconatactform {
  flex-wrap: wrap;
}
.imgheight {

  margin-left: 40px;
}
.card-header{
 background-color: #FFFFFF;
}
.askedquestion {
 font-size: 50px;
 font-weight: 600;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
  
}
.maindivfrequ .card-header {
  background: #ffffff !important;
  border: 1px solid #ff8159;
  border-radius: 6px;
  opacity: 1;
}
.maindivfrequ {
  width: 91%; /* Ensure it takes the full width of its container */
 /* Restrict the maximum width */
  height: auto; /* Allow height to adjust based on content */
  background:url("../src/Images/imgfaq.svg") no-repeat 99% 56px    #eef2f6;
  border-radius: 65px; background-size: 7%;
  margin: 100px auto; /* Center the div and add some top/bottom margin */
  padding: 20px; /* Add padding for better content spacing */
  box-sizing: border-box; /* Ensure padding is included in the width/height */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .maindivfrequ {
    border-radius: 20px; /* Smaller border radius for small screens */
    padding: 15px; /* Adjust padding for small screens */
  }
}

.maximize {
  display: flex;
  align-items: center;
  justify-content: center;
}

.itrbox44main .card-title {
  font: normal normal bold 36px/45px Plus Jakarta Sans;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
}

.itrbox44main .card-text {
  font: normal normal normal 24px/34px Plus Jakarta Sans;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
}

.itrbox44main .card {
  padding: 20px;
}
.itrbox44main{
  width: 91%;
  margin: 0px auto;
}
.icon-wrapper {
  width: 50px;
  height: 50px;
  background-color: #ff8159;
}

.icon-img {
  width: 100%;
  height: auto;
}

.maincardmaximize {
  background-color: #fff;
  border-radius: 40px;
  margin-right: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 1;
  
  padding: 50px 30px;
  min-height: 345px;
}
.yout{
  padding:100px 0px;
}
.di{
  padding: 100px 0px;
}
.ITRbox4 h3 {
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  padding-bottom: 18px;
  margin: auto;

  
}

.ITRbox4 {
  border-radius: 65px;
  opacity: 1;
}

.maximizeh112 {
  text-align: left;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
  font-weight: bold;
  font-size: 36px;
}

.maximizeh111122 {
  text-align: left;
  letter-spacing: 0px;
  color: #0c1524;
  line-height: 34px;
  font-size: 24px;
  opacity: 1;
}


/* Responsive Styles */
@media (max-width: 850px) {
  .itrbox44main .maincardmaximize {
    flex-wrap: wrap;
  }
  .itrbox44main .flex-1 {
    min-width: 100%;
  }
  .itrbox44main .containerr {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 450px) {
  .itrbox44main .icon-wrapper {
    width: 40px;
    height: 40px;
  }
  .itrbox44main .maximizeh112 {
    font-size: 18px;
  }
  .itrbox44main .maximizeh111122 {
    font-size: 16px;
  }
}
.itrbox44main{
  padding: 100px 0px;
}
.login {
  background-color: #ff8159 !important;
}
.modaltele {
  background-color: #157347;
}
.copyright {
  margin-left: 210px;
  font-size: 14px;
}
.copyrightmain span {
  font-size: 14px;
}
.fileyour {
  font-size: 16px;
}
.mainfooterdiv {
  display: flex;
}
.btnn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lika {
  text-decoration: none;
  font-size: 16px;
}
.btnn button {
  display: flex;
}
.usera {
  margin-right: 15%;
}
.submitbtn {
  text-align: center;

  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
}

.contactmail {
  text-align: left;
  margin-left: 35px;
  letter-spacing: 0px;
  color: #0c1524;
  font-size: 18px;
  opacity: 1;
}
.contactmail span {
  margin-left: 65px;
}

.howitworkd {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bolsals {
  text-align: center;

  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
}
.simplifylogo {
  background: #ff8159 0% 0% no-repeat padding-box;
  opacity: 1;
  top: 8402px;
  left: 909px;
  width: 70px;
  height: 70px;
  border-radius: 45px;
  justify-content: center;
}
.maincard {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ff8159;
  border-radius: 40px;
  opacity: 1;
  top: 8352px;
  left: 326px;
  width: 402px;
  height: 300px;
  padding-top: 25px;
}

.headingresiter {
  text-align: center;
  font-size: 30px;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
}
.contentcard {
  text-align: center;
  font-size: 18px;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
}

.simplifylogo {
  background: #ff8159 0% 0% no-repeat padding-box;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .simplifylogo {
    margin-bottom: 20px;
  }
}

.maincontactus {
  display: flex;
  align-items: center;
  justify-content: center;
}
.conteysa {
  color: var(--Dark, #0c1826);

  /* body 1 */
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 26.1px */
}

.cardstyleo {
  padding-bottom: 80px !important;
  border-radius: 40px !important;
}

.yaerea {
  color: var(--dark, #090a13);
  text-align: center;
  font-size: 30px;
  font-style: normal;

  line-height:38px;
}
.yaerea span{  font-size: 72px; font-weight: 800;}
.aboutbtn {
  width: max-content;
  border: 1px solid #ff8159;
  border-radius: 10px;
  opacity: 1;
  padding: 15px 23px;
  color: #ff8159;
  font-size: 24px;
  margin-top: 45px;
}
.legal {
  text-align: start;
  font-size: 25px !important;
  font-weight: bold;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
  margin-bottom: 20px;
}
.rebates {
  font-size: 18px;
}
.seize {
  color: var(--Dark, #0c1826);
  text-align: center;

  /* body 1 */
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 26.1px */
}
.getstat {
  border-radius: 8px;
  background: var(--Blue-dark, #0056b3);
  padding: 8px 12px;
}
.simple {
  color: var(--Dark, #0c1826);
  text-align: center;
  font-family: Montserrat;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 1060px;
}

input[type="email"],
input[type="tel"],
textarea {
  color: #333;
}

.mainfrewq {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blueback {
  border-radius: 12px;
  background: var(#eaf4ff);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ff8159;
  border-radius: 6px;
  opacity: 1;
}
.frequ {
  color: var(--Dark, #0c1826);

  /* Large 2 */

  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.mazimiazxx {
  justify-content: center;
}
.submitbtn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ff8159;
  border-radius: 10px;
  opacity: 1;
  padding: 6px 8px;
}

.head {
  color: var(--dark, #090a13);
  text-align: center;

  /* Large 2 */

  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.textta {
  color: var(--Blue-darker, #1d3d64);

  /* Heading 3 */
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 26px */
}
.income {
  text-align: center;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
  font-size: 36px;
}
.cardtext123 {
  margin-left: 20px;
font-size: 29px;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
}
.numberincome {
  text-align: center;
  font-size: 48px;
  display: block;
  margin-bottom: 40px;
  margin-top: 10px;
  line-height: 61px;
 color: #0c1524;

}
.maindivsecond .card{ width: 95%;}
.bg-light-blue{ margin-bottom: 70px;}

.incometitle{   font-size: 36px; }

.income {
  font-size: 36px !important;
}

.cardtitle1223 {
  font-size: 30px;

  opacity: 1;
}
.cardfile {
  border-radius: 40px !important;
}
.getstartbtn {
  display: flex;
  align-items: center;
  margin-left: 35px !important;
}
.trherr {
  text-align: center;
  font-size: 50px;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
}
.mainrow p.card-text {
  display: flex;
}

.sectionthird p {
  font-size: 18px;
}
.sectionthird {
  background-color: #eef2f6;
  padding-bottom: 40px;
  border-radius: 40px;
  
  
  margin-top: 80px;
}
.navbar {
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.navbar.scrolled {
  background: rgba(51, 170, 51, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 70px;
}
.alllinks {
  text-align: left;

  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
}
.sectionthird .card-title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}
.mainfooter1 {
  background-color: #eef2f6;
  width: 100%;
}
.footermain {
  justify-content: space-between;
}

.btnclas {
  background-color: #0056b3;
}
.conatiner4 {
  padding: 50px;
  padding-bottom: 100px;
}
.maximizeh1 {
  color: #0c1826;
}

.cardone {
  left: 326px;
  width: 617px;
  height: 308px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 1;
}
.cardonee {
  margin-top: 120px;
}

.maximizeh12 {
  text-align: center;
  font-size: 50px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-weight: 600;
}
.backgoundimg {
  background-image: url("./Images/img-Dont-Miss-Out.jpg");
  background-size: cover;

  
}

.numberbackground {
  background-color: #1d3d64;
  border-radius: 180px;
  padding: 8px 4px;
}

a.nav-link {
  color: #000;
  background: #fff;
  border-radius: 5px;
}

a.nav-link:hover {
  color: #000;
}

.numbers span {
  padding: 10px 0 0 22px;
  width: 70px;
  height: 70px;
}

.savsize {
  height: 50px;
  width: 50px;
}

.savsize span {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

form input {
  margin-bottom: 15px;
}

.fobn {
  background-color: #000;
  color: white;
  font-size: x-large;
}
.paragraph111 {
  text-align: center;
  font-size: 24px;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
}

.navmain {
  background: rgba(51, 170, 51, 0.1);
  height: 70px;
}
.h33 {
  text-align: center;

  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
}
.assited {
  font-size: 16px;
}
.containerr {
  padding-bottom: 20px;
}
.containerrr {
  background-color: #055160;
}
.Seize {
  font-size: 24px;
  margin-bottom: 40px;
}
.bg-image {
  top: 80pxpx;
  left: 161px;
  width: 599px;

  background: #eef2f6 0% 0% no-repeat padding-box;
  border-radius: 70px;
  opacity: 1;

  background-size: cover;
  background-position: center;
  min-height: 690px;
}

.bgimagemain {
  width: 90%;
  background-size: cover;
  margin-right: 10px;
  margin-left: 85px;
}
.techgenius {
  font-size: 14px;
}
.checksize {
  width: 24px;
}
.checksizeee {
  padding-right: 4px;
}

.btnclass {
  background-color: #007bff;
  border-radius: 10px;
  padding: 8px 8px;
  color: #fff;
  text-align: center;
}

.termsanansc {
  text-decoration: none;
  color: white;
}
.cardimgg {
  border-radius: 40px 40px 0 0 !important;
}

.cmy-5 {
  margin-top: 100px;
}

@media (min-width: 992px) {
  .rounded-sm {
    margin: 0 2rem;
  }

  iframe {
    width: 960px;
    height: 484px;
  }
}

@media (max-width: 767px) {
  iframe {
    width: 100%;
    height: auto;
  }
}

.lastsection {
  font-size: 50px;
  color: #fff;
}
.backgroundimg {
  background-image: url("./Images/img-bg-feedback.jpg");
  margin-bottom: 80px;
}
.parafont {
  font-size: 18px;
  margin-top: 20px;
}

.mainfooter1 {
  background-color: #f8f9fa;
}

.mainfooterdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fileyour {
  font-size: 16px;
  line-height: 21px;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #0c1524;
  opacity: 1;
}

.alllinks a {
  display: block;
}

.copyrightmain {
  margin-top: 20px;
}

.copyright span,
.copyright a {
  color: #0c1524;
}

.back-to-top-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.imagehome {
  margin-left: 148px;
}
.backgroundimg {
  background-color: #eef2f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 90px 5px;
  box-sizing: border-box;
  gap: 50px;
  max-width: 100%;
  text-align: center;
  font-family: "YourFontFamily", sans-serif;
}

.backgroundimg h1 {
  font-size: 50px;
  color: #0c1524;
}

.aboutsec .custom-card{ border: none !important; min-height: 472px; text-align: left;}
.aboutsec .custom-card h3{ margin-top: 0px;}
.aboutsec .custom-card p{ line-height: 25px;}
.aboutsec .custom-card .author {
  font-size: 20px;
  font-weight: bold;
}

.custom-card {
  background-color: #ffffff;
  border: 1px solid #ff8159;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px;
  flex: 1;
  min-width: 291px;
  max-width: 100%;
}

.custom-card img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.custom-card h3 {
  font-size: 30px;
  font-weight: bold;
  color: #0c1524;
  margin-top: 20px;
}

.custom-card p {
  font-size: 18px;
  color: #0c1524;
  margin-top: 20px;
  line-height: 30px;
}

.custom-card .author {
  font-size: 1rem;
  font-weight: bold;
}

.custom-card .designation {
  font-size: 0.875rem;
  opacity: 0.8;
  margin-top: -10px;
}

.formsection form {
  display: flex;
}

/* footer css */
.mainfooter1 {
  background-color: #f8f9fa;
}

.fileyour {
  font-size: 1rem;
  color: #6c757d;
}

.alllinks .lika {
  color: #343a40;
  transition: color 0.3s;
}

.alllinks .lika:hover {
  color: #007bff;
}

.social-icon {
  width: 24px;
  height: 24px;
}

.back-to-top-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: none;
}

.back-to-top-icon {
  display: block; /* Ensure the icon is displayed */
}

.assitedee {
  margin-left: auto;
}

.homemaindiv {
  height: 480px;
  background: #eef2f6 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 1;
}

.assitedee {
  text-align: left;
  font: normal normal 600 50px/56px Plus Jakarta Sans;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
  margin-top: 200px;
}

.whatsapp-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  display: flex;
  align-items: center;
  padding: 10px; /* Adjust padding as needed */
  text-decoration: none;
}

.icon-text-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid black; /* Add border to icon and text */
  border-radius: 4px; /* Adjust border radius if needed */
}

.whatsapp-icon {
  margin-right: 10px; /* Space between icon and text */
}

.share-button {
  background: transparent;
  border: none;
  color: #000;
  cursor: pointer;
  padding: 0; /* Remove default button padding */
  margin: 0; /* Remove default button margin */
}
.applybtn {
  border-radius: 5px;
  background-color: #ff8159;
  padding: 6px 6px;
  width: 90px;
  color: white;
}
.applybtnn {
  border-radius: 5px;
  background-color: #ff8159;
  padding: 8px 15px;

  color: white;
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 30px;
}
.inputofform {
  width: 30%;
}

.submitbtn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ff8159;
  border-radius: 4px;
  opacity: 1;
  text-transform: uppercase;
  font-size: 20px;
}

.mt-3 {
  margin-top: 1rem !important;
}

.d-flex {
  display: flex !important;
}

.align-items-center {
  align-items: center !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.download-button img {
  height: 24px; /* Adjust size as needed */
  width: 24px; /* Adjust size as needed */
}

.download-button button {
  background: none;
  border: none;
  color: blue; /* Adjust color as needed */
  cursor: pointer;
  font-size: 1rem;
  padding: 0;
}

.download-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #999999;
  border-radius: 4px;
  opacity: 1;
  height: fit-content;
  text-decoration: none;
}

.bg-imagee {
  background: #eef2f6 no-repeat;
  border-radius: 65px;
  opacity: 1;
  background-size: cover;
  background-position: center;
  min-height: 480px;
}

.ticketfontsize {
  font-size: 24px;
}

.container {
  max-width: 1200px; /* Adjust the maximum width as needed */
  margin: 0 auto;
  padding: 0 15px; /* Adjust the padding as needed */
}

.referalcodeshare {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}



@media (max-width: 700px) {
  .backgroundimg {
    padding: 58px 5px;
    gap: 25px;
  }

  .backgroundimg h1 {
    font-size: 2.5rem;
  }

  .custom-card {
    padding: 15px;
  }

  .custom-card h3 {
    font-size: 1.25rem;
  }

  .copyright {
    margin-left: 0px;
    font-size: 14px;
}

.bg-image{ padding-bottom: 15px;}
.secondsection .tick-small{ font-size: 15px;  line-height: 20px;}  
.footerlink{ margin-left: 85px;}
.termsandcon ol , .termsandcon ul { margin: 0px; padding: 0 0 0 15px;}

  .custom-card p {
    font-size: 0.875rem;
  }
  .back-to-top-icon {
    display: block;
  }

  .cardmainn {
    margin-top: 15px;
    
  }

  .maindivfrequ{ display: none;}
  .cardtext123{        font-size: 20px;}
  .numberincome{  font-size:34px; }
  .youtubwtexttt{ display: contents !important;}
  .yout {
    padding: 30px 0px;
}
.youtubwtexttt .text-content {
  font-size: 25px;
}

.maximizeh111122{ line-height: 24px;}

.youtubwtext  .responsive-img {
  width: 83%;
  height: auto;
  margin: 20px;
}

.di {
  padding: 30px 0px;
}

.sectionthirdwqw{ padding: 0px;}
.itrboxtwo{ padding: 50px 0 20px 0;}
.ITRbox4 {
  background-color: #EEF2F6;
  padding: 50px 0 30px 0;
}

  .maincontactus{ margin-top: 30px;}
  .sectionthird h2{ padding-bottom: 0px;}
  .cardfile {
    margin-top: 15px;
  }

  .itrtitle {
    font-size: 25px;
    font-weight: 600;
  }

  .trherr {
    font-size: 25px;
  }

  .assitedee {
    font-size: 20px;
    margin-top: 50px;
    margin-left: 35px;
  }

  .itrtitle {
    font-size: 20px !important;
   font-weight: 600;
  }

  .tick-small{ margin-right: 20px;}


.sectionthird .mx-5{ margin-left: 0px !important;}

  .downlaodnutton {
    display: none;
  }

  .trherr {
    font-size: 25px !important;
  }

  .dicoverheading {
    font-size: 25px !important;
    font-weight: 600;
  }

  .secp {
    font-size: 25px !important;
  }

  .ITRbox3 h3 {
    font-size: 25px !important;
  }

  .ITRbox4 h3 {
    font-size: 25px !important;
    background-color: #EEF2F6;
    border-radius: 65px;
  }

  .aboutsec .ITRbox4 h3{ background: none;}

  .maximizeh12 {
    font-size: 25px !important;
  }
  .imagehome {
    margin-left: auto;
  }
  .head {
    font-size: 20px !important;
  }

  .askedquestion {
    font-size: 25px !important;
  }

  .constachead {
    font-size: 30px !important;
    font-weight: 600;
  }

  .main {
    overflow-x: hidden;
  }

  .ticketfontsize {
    font-size: 12px;
  }

  .referalcodeshare {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .refralwidth {
    margin-bottom: 15px;
  }
  .applybtn {
    margin-top: 0px;
  }
  .navbartoggleclass {
    background-color: #ff8159 !important;
  }

  #main-tabs-tabpane-salary .d-flex {
    display: contents !important;
  }
  #mainrefrel .d-flex {
    display: contents !important;
  }

  #main-tabs-tabpane-salary .pr-10 {
    display: contents !important;
  }

  #main-tabs-tabpane-business .d-flex {
    display: contents !important;
  }
  #main-tabs-tabpane-business .pr-10 {
    padding-right: 0px !important;
  }

  .download-button button {
    margin-bottom: 20px;
  }
  .formexel span {
    margin-bottom: 12px !important;
    display: block;
    width: 100% !important;
  }

  .googleratings {
    margin-top: 61px;
  }

  .contactmail {
    margin-left: 0px;
    margin-top: 20px;
    font-size: 16px;
  }
  .contactmail span {
    margin-left: 0px;
    display: block;
    margin-top: 10px;
  }

  .btngetstarted {
    padding: 4px;
    font-size: 23px;
  }
  .discover {
    margin-top: -38px;
  }
  .sectionthird .card-title{
    font-size: 21px;
  }
  .discoverpage{
    font-size: 15px;
    line-height: 24px;
  }
  .maincardmaximize{ min-height: auto;    padding: 15px 30px;}

  .itrbox44main .maximizeh112 {
    font-size: 24px;
}

.termsandcon h1 {
  font-size: 30px;
}


  .seccp {
    font-size: 16px;
  }
  .btngetstarted {
    font-size: 19px
  }
  .paragraph111 {
 font-size: 18px;
}

.formexel {
  font-size: 16px;
}
.formexel span {
  width: 198px;
}
.titletext {
 font-size: 18px;
}

}
.ITRbox4{
  background-color: #EEF2F6;
  padding: 100px 0;
 
}
.custom-input:focus {
  border-color: #ff5733 !important; /* Your desired color */
  box-shadow: 0 0 5px rgba(255, 87, 51, 0.5); /* Optional: to add a shadow effect */
}
.mainyoutube{
  margin-top: 10px;
  margin-left: 20px;
}



/* Default styles */
.document-status {
  display: flex;
  align-items: center;
  position: relative;
}

.status-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.status-circle {
  padding: 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content:space-evenly;
  align-items: center;
  color: white;
  margin-bottom: 5px;
  position: relative;
}



.status-label {
  min-width: 150px; /* Example width, adjust as needed */
  text-align: center; /* Center text horizontally */
  padding: 10px; /* Adjust padding as needed */
  margin: 0 5px;
  border-radius: 5px;
  white-space: nowrap;
  color: #fff;
  display: inline-block; /* Ensures the divs are treated as blocks and align correctly */
}

  .spinner{
    color: #ff8159;
  }


.status-line {
  position: absolute;
  top: 15px; /* Align with the middle of the number circles */
  height: 2px;
}

@media (max-width: 600px) {
  .document-status {
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 300px;
    flex-wrap: wrap;
    position: relative;

  }
  .status-item {
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
  }
  .status-circle {
    margin-bottom: 0;
    margin-right: 10px;
  }
  .status-line {
    position: relative;
    top: 0;
    left: 0;
    width: 2px;
    height: 20px;
    margin: 0 15px;
    display: none;
  }
  .lead-form {
    font-size: 14px;
    padding: 10px;
  }
 
}

